import React from 'react'
import PropTypes from 'prop-types'
/**
 * 
 * @param {number} currentPage - current page results 
 * @param {number} totalPages - results lenth / resultsPerPage
 * @param {func} setpageIndex - set  current page
 */
export default function SearchPagination({ setNavInputVal, navInputVal, currentPage, totalPages, setpageIndex }) {


  const prevPage = () => {
    if (currentPage !== 1) {
      setpageIndex(currentPage - 1)
      setNavInputVal(currentPage - 1)
    }
  }
  const nextPage = () => {
    if (currentPage !== totalPages) {
      setpageIndex(currentPage + 1)
      setNavInputVal(currentPage + 1)
    }
  }

  return (
    <div>
      <nav className="pagination pagination--search">

        {/* Previous resutls link */}
        <div
          title="Go to previous page"
          //eslint-disable-next-line
          className={`pagination__box pagination__box--prev ${currentPage == 1 && 'pagination__box--prev-unactive'}`}
          onClick={prevPage}
          onKeyDown={(e) => e.keyCode === 13 && prevPage()}
          role="button"
          tabIndex="0"
        >
        </div>

        {/* Navigation Input field */}
        <div className="pagination__nav">
          <input
            className="pagination__nav-curr"
            onChange={(e) => setNavInputVal(parseInt(e.target.value) > totalPages ? totalPages : e.target.value)}
            value={navInputVal}
            type="number"
            min="0"
            max={totalPages}
            onKeyDown={(e) => e.keyCode === 13 && setpageIndex(parseInt(navInputVal))}
            role="button"
            tabIndex="0"
          />
          <div className="pagination__nav-total"> / {totalPages}</div>
        </div>

        {/* Next results link */}
        <div
          className={`pagination__box pagination__box--next ${currentPage === totalPages && 'pagination__box--next-unactive'}`}
          title="Go to next page"
          onClick={nextPage}
          onKeyDown={(e) => e.keyCode === 13 && nextPage()}
          role="button"
          tabIndex="0"
        >
        </div>
      </nav>
    </div>
  )
}

SearchPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setpageIndex: PropTypes.func
}