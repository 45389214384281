import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"


//Components
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
//Internal componentes
import Header from './Header'
import Footer from './Footer'
import CookiePopUp from './CookiePopUp'
import SearchPagination from './SearchPagination'
//Styles
import './SearchResults.scss'

// https://stackoverflow.com/a/24006120/2255980
// this function will convert the absolute paths that GraphQL gives us 
// into relative paths, which is what Gatsby Link needs.
// so for example 
// this https://localhost:8000/a-post-about-bagels 
// becomes /a-post-about-bagels
function localiseUrl(url) {
  try {
    const newURL = new URL(url)
    return newURL.pathname
  } catch {
    return url
  }
}

const minCharacters = 3
// we trim the results text to this value, else we dont know how much content 
// we're rendering onto the page
const contentCharLimit = 180


function findQueryInData(query, data) {
  let queriedList = []
  if (!data) {
    return false
  }
  data.forEach(item => {
    if (typeof item.title === 'string') {
      if (item.title.toLowerCase().includes(query)) {
        return queriedList.push(item) //return is used here so program can skip searching into content if item is already added to list
      }
    }
    if (item.content) {
      if (item.content.toLowerCase().includes(query)) {
        return queriedList.push(item)
      }
    }
  })

  return queriedList
}

//Search result component render search header & search results
export default function SearchResults({ data, language, translations }) {

  const { allWpPost, allWpPage, allWpOrganization, allWpMenu, allWp: { nodes: [optionsPage] } } = data

  //Content is returned with html tags in string
  //So here using regex html tags will be removed from contnet
  allWpOrganization.nodes.map(item => {
    let regexContent = ''
    const regex = /(<([^>]+)>)/ig;
    if (item.content !== null) {
      regexContent = item.content.replace(regex, '')
    }
    return item.content = regexContent
  })

  let allSearchData = [...allWpPage.nodes, ...allWpPost.nodes]
  allSearchData = allSearchData.concat(allWpOrganization.nodes)

  //Query search state
  const [query, setQuery] = useState('') // our search query string
  const [navInputVal, setNavInputVal] = useState(1)
  const [pageIndex, setpageIndex] = useState(1)
  const [queryResults, setQueryResults] = useState([])

  const resultsPerPage = 12;


  const getSearchParamsFromUrl = () => typeof window
    !== `undefined` ? decodeURIComponent(window.location.search.substr(1))
    : null

  //submit search with parameter from url
  const submitsearchOnLoad = () => {
    // setQuery(getSearchParamsFromUrl().replace(/[|&!*#;$=%^_/?@":~<>()+,]/g, ""))
    setQuery(getSearchParamsFromUrl().replace(/%/g, "").replace(/20/g, ' '))
  }
  //On submit set query state

  const submitSearch = (e) => {

    setpageIndex(1)
    setNavInputVal(pageIndex)
    setQuery(e.target.value)

  }

  //Node type text 
  const searchNodeType = (nodeType) => {
    switch (nodeType) {
      case 'Post':
        return 'News'
      case 'Page':
      case 'Organization':
        return 'Other'
      case 'Discount':
        return 'Discount'
      default:
        break;
    }
  }

  useEffect(() => {

    getSearchParamsFromUrl()
    submitsearchOnLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (query) {
      setQueryResults(findQueryInData(query.toLowerCase(), allSearchData))
    }

  }, [query])

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, pageIndex)


  /**
   * Depeans of CPT some urls will be modified 
   * Discount & Organisations don't have single pages so it redirect to archive page
   * For news url is modified, it has news/frettir before slug
   * Articles & pages url are unchangable
   * @param {object} post 
   * @param {string} postLink 
   */
  const searchResultLink = (post, postLink) => {
    if (post.nodeType === 'Post') {
      const cptTranslation = post.language.locale === 'en_US' ? '/en/news' : '/frettir';
      return `${cptTranslation}/${post.slug}`
    } else if (post.nodeType === 'Discount') {
      return post.language.locale === 'en_US' ? '/en/discounts' : '/afslaettir'
    } else if (post.nodeType === 'Organization') {
      return post.language.locale === 'en_US' ? '/en/student-organisation' : '/nemendafelog'
    } else {
      return postLink
    }
  }
  const isEn = language.locale === 'en_US'
  //Static content for search this page 
  const searchStaticContent = {
    pleaseType: isEn ? `Please type at least ${minCharacters} characters ` : `Vinsamlegast skrifið a.m.k. ${minCharacters} stafi`,
    resultLenth: isEn ? `${queryResults.length} results for ` : `${queryResults.length} niðurstöður fyrir `,
    noResult: isEn ? `No results found for ` : 'Engar niðurstöður ',
    noSearchQueryResults: isEn ? 'Sorry, your search query did not return any results.' : 'Því miður, leitarfyrirspurn þín skilaði engum niðurstöðum'
  }

  return (
    <>
      <Header
        menuData={allWpMenu}
        language={language}
        translations={translations}
        isOnStartSearchOpen={true}
        isOnSearchPage={true}
        onSearchSubmit={submitSearch}
        searchParamValue={getSearchParamsFromUrl() && getSearchParamsFromUrl().replace(/%/g, "").replace(/20/g, ' ')}
      />

      {/* Search lenght results */}
      <section className="search-count-res content-wrapper">
        <h2>
          {query.length < minCharacters && searchStaticContent.pleaseType}
          {!!queryResults.length && query.length >= minCharacters && searchStaticContent.resultLenth}
          {!queryResults.length && query.length >= minCharacters && searchStaticContent.noResult}
        </h2>
        <h2 className="strong">
          {query.length < 25 ? query : `${query.slice(0, 25)}...`}
        </h2>
        <p className="search-count-res__no-res">
          {!queryResults.length && query.length >= minCharacters && searchStaticContent.noSearchQueryResults}
        </p>
      </section>


      <section className="search-res content-wrapper">
        {/* ensure the minimum number of characters has been entered */}
        {query.length >= minCharacters &&
          <div className="search-res__wrapper">
            {queryResults && queryResults.map((searchResult, index) => {
              // Filter through all of the graphql data provided by the parent component
              // to find the ID which matches the results provided by Lunr
              // and just grab the first and only item (it'll be a single item array)

              // const post = allSearchData.filter(post => post.id === searchResult.ref)[0]

              const content = searchResult.content ? searchResult.content : null
              const contentCapped = `${content?.substring(0, contentCharLimit)}${content?.length > contentCharLimit ? '...' : ''}`

              const postLink = searchResult.link ? localiseUrl(searchResult.link) : searchResult.nodeType === 'Discount' ? '/discounts' : '/organizations'

              //Get 12 results per page
              if ((pageIndex - 1) * resultsPerPage <= index && index < pageIndex * resultsPerPage) {
                return (
                  <div key={index} className={`search-res__single ${searchResult.id}`}>
                    {searchResult.featuredImage
                      ? <Link className="search-res__single-img-link" to={searchResultLink(searchResult, postLink)}>
                        <BackgroundImage className="search-res__single-img" fluid={searchResult.featuredImage.node.file.image.fluid} />
                      </Link>
                      : null
                    }
                    <div className="search-res__single-content">
                      <p className="search-res__single-node">{searchNodeType(searchResult.nodeType)}</p>
                      <Link to={searchResultLink(searchResult, postLink)}>
                        <h2>
                          {searchResult.title}
                        </h2>
                      </Link>
                      { }
                      {content && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: contentCapped
                          }}
                        />
                      )}
                    </div>
                  </div>
                )
              }
              return null
            })}
          </div>
        }


      </section>

      {queryResults.length > 0 &&
        <SearchPagination navInputVal={navInputVal} setNavInputVal={setNavInputVal} currentPage={pageIndex} setpageIndex={setpageIndex} totalPages={Math.ceil(queryResults.length / resultsPerPage)} language={language} />
      }
      <CookiePopUp language={language} />
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={optionsPage} />

    </>
  )

}

SearchResults.propTypes = {
  data: PropTypes.object.isRequired
}